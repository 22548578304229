<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex justify-content-between">

        <h1 class="my-auto">
          <span v-if="isAdminOrAgentMTCA"> Liste des agents d'établissement </span>
          <span v-else> Liste des agents de l'établissement </span>
        </h1>

        <b-button
          v-if="$can('create','agentEtablissement')"
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({ name: 'etablissements-agents-create' })"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter un agent</span>
        </b-button>
      </div>
      <!-- <b-alert
        class="mt-2"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Note !
        </h4>
        <div class="alert-body">
          <span>Un établissement ne peut créer que trois(03) agents</span>
        </div>
      </b-alert> -->
    </b-card>

    <b-card>
      <ListAgentsComponent
        :etablissement-id="etablissementId"
        @canAddNewAgent="canAddNewAgent=$event"
      />
    </b-card>
    <div
      v-if="tableData && tableData.length > 1"
      class="d-flex pagresp justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <!-- <div class="d-flex flex-row">  -->
        <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import utilsService from '@/services/utils/utils.service'
import ListAgentsComponent from './components/ListAgentsComponent.vue'

export default {
  name: 'ArticleList',
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    BAlert,
    ListAgentsComponent,
  },
  setup() {
    const { currentUserId, etablissementId } = utilsService.currentUserUtils()
    return {
      etablissementId,
    }
  },
  data() {
    return {
      tableData: [],
      filters: { options: {} },
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      canAddNewAgent: false,
    }
  },
  computed: {
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'agent_mtca' || userRole.code === 'super_admin'
    },
  },
  watch: {},
  async mounted() {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.pagresp {
  @media (max-width: 768px) {
    flex-wrap: wrap !important;
  }
}
</style>
